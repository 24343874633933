import { Component, useContext } from "react";
import { ApiContext } from "../api/context";

class CPurchaseHistory extends Component {

    state = {
        payments: undefined
    }

    componentDidMount() {
        this.props.apiContext.paymentsService.getPayments()
            .then(res => {
                this.setState({
                    payments: res.data.sort((a, b) => b.startDate - a.startDate)
                })
            })
            .catch(err => console.error(err))
    }

    render() {
        if (!this.state.payments) return <h5>Loading payments...</h5>;

        return (
            <div className="purchase-history">
                <table className="purchase-history-table">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Name</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Expired</th>
                            <th>Revoked</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.payments.length < 1 &&
                            <h5>No payments found</h5>
                        }
                        {this.state.payments.map(payment => {
                            let expiry = undefined
                            if (payment.duration) {
                                expiry = new Date(payment.startDate.valueOf());
                                expiry.setDate(payment.startDate.getDate() + payment.duration);
                            }
                            return (
                                <tr key={payment.paymentId}>
                                    <td>{payment.paymentId}</td>
                                    <td>{payment.packageName}</td>
                                    <td>{payment.startDate.toLocaleString()}</td>
                                    <td>{expiry?.toLocaleString() ?? "Never"}</td>
                                    <td>{expiry && expiry.valueOf() < Date.now ? "Yes" : "No"}</td>
                                    <td>{payment.revoked ? "Yes" : "No"}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default function PurchaseHistory(props) {
    const apiContext = useContext(ApiContext);
    return <CPurchaseHistory {...props} apiContext={apiContext} />;
}