import { Component, useContext } from 'react';
import { ApiContext } from '../api/context';
import DiscordInvite from './DiscordInvite';
import PageWrapper from './PageWrapper';
import PurchaseHistory from './PurchaseHistory';

class Profile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loggingOut: false,
            discordAction: false
        }
    }

    logout = () => {
        this.setState({ loggingOut: true });
        this.props.api.authService.logout()
            .catch(e => this.setState({ loggingOut: false }));
    }

    linkDiscord = () => {
        this.setState({ discordAction: true });
        this.props.api.authService.linkDiscord()
            .catch(() => this.setState({ discordAction: false }));
    }

    unlinkDiscord = () => {
        this.setState({ discordAction: true });
        this.props.api.authService.unlinkDiscord()
            .finally(() => this.setState({ discordAction: false }));
    }

    linkedDiscordCard = () => {
        return (
            <div className="linked-discord-wrapper">
                <div>
                    <h3>{this.props.api.user.discordName}#{this.props.api.user.discordDiscriminator}</h3>
                    <h6>{this.props.api.user.discordId}</h6>
                    <p>You're all linked and ready to go!</p>
                </div>
                <div>
                    <button disabled={this.state.discordAction} onClick={this.unlinkDiscord} className="full-button">{this.state.discordAction ? "Unlinking...." : "Unlink Discord"}</button>
                </div>
            </div>
        );
    }

    unlinkedDiscordCard = () => {
        return (
            <div className="linked-discord-wrapper">
                <div>
                    <h3>No Discord Profile Found!</h3>
                    <p>Start the linking process by clicking the button on the right</p>
                </div>
                <div>
                    <button disabled={this.state.discordAction} onClick={this.linkDiscord} className="full-button">{this.state.discordAction ? "Loading...." : "Link Discord"}</button>
                </div>
            </div>
        );
    }

    render() {
        const discordLinked = this.props.api.user.discordId;

        return (
            <PageWrapper>
                <div className="profile-wrapper">
                    <div className="profile-header">
                        <div className="profile-persona">
                            <img alt="Profile" className="profile-image" src={this.props.api.user.avatar} />
                            <h1 className="profile-name">{this.props.api.user.userName}</h1>
                        </div>
                        <button disabled={this.state.loggingOut} onClick={this.logout} className="full-button large-button">{this.state.loggingOut ? "Logging out..." : "Logout"}</button>
                    </div>
                </div>
                <h2>Discord Linking</h2>
                <div className="discord-linking-wrapper">
                    <div className="discord-information">
                        <p>Linking your discord account is a requirement for receiving your VIP related roles in Discord that can be found on our <a href="https://store.raidsimulator.com" target="__blank">Store</a></p>
                        {discordLinked && this.linkedDiscordCard()}
                        {!discordLinked && this.unlinkedDiscordCard()}
                    </div>
                    <div className="discord-frame">
                        <div className="discord-invite-group">
                            <DiscordInvite inviteCode="73hNzpTv3Q" />
                        </div>
                        {/* <iframe title="Discord" src="https://discord.com/widget?id=389320911318417409&theme=dark" allowtransparency="true" frameBorder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe> */}
                    </div>
                </div>
                <h2>Purchase History</h2>
                <div className="purchase-history-wrapper">
                    <p>Here you will find a list of packages you have been gifted or purchased on our <a href="https://store.raidsimulator.com" target="__blank">Store</a></p>
                    <br />
                    <PurchaseHistory />
                </div>
            </PageWrapper >
        )
    }
}

export default function Wrapper(props) {
    const api = useContext(ApiContext);
    return <Profile {...props} api={api} />;
}