import { ApiError } from '../models/ApiError';

export class BaseService {
    constructor(baseUrl, getAccessToken) {
        this.baseUrl = baseUrl
        this.getAccessToken = getAccessToken;
    }

    _getBaseHeaders = () => {
        if (!this.getAccessToken || typeof (this.getAccessToken) !== "function") return {};
        try {
            const token = this.getAccessToken();
            if (token && typeof (token) === "string") {
                return { Authorization: 'Bearer ' + token };
            }
            return token ?? {};
        }
        catch { }
        return {};
    }

    get = (url) => {
        const reqInit = {
            method: 'GET',
            credentials: 'include',
            headers: this._getBaseHeaders()
        };

        return this._sendRequest(url, reqInit);
    }

    post = (url, content) => {
        const reqInit = {
            method: 'POST',
            credentials: 'include',
            headers: this._getBaseHeaders(),
            body: JSON.stringify(content)
        };

        return this._sendRequest(url, reqInit);
    }

    put = (url, content) => {
        const reqInit = {
            method: 'PUT',
            credentials: 'include',
            headers: this._getBaseHeaders(),
            body: JSON.stringify(content)
        };

        return this._sendRequest(url, reqInit);
    }

    async _sendRequest(url, requestInit) {
        url = this.baseUrl + "/" + url;

        const res = await fetch(url, requestInit);
        const responseBody = await res.text();
        if (res.status >= 200 && res.status < 300) {
            if (responseBody.length > 0)
                return JSON.parse(responseBody);
            return;
        }

        throw new ApiError(responseBody, res.status);
    }
}