import { BaseService } from "./BaseService";
import jwt_decode from 'jwt-decode';

export class AuthService extends BaseService {
    static accessTokenLocalStorageKey = 'access_token';
    static refreshTokenLocalStorageKey = 'refresh_token';

    accessToken;
    expiresAt;
    refreshToken;

    accessTokenExpiringTimeout;
    accessTokenExpiredTimeout;

    constructor(onUserUpdated, onDiscordUnlinked, baseUrl) {
        super(baseUrl, () => this.accessToken)
        this._onUserUpdated = onUserUpdated;
        this.onDiscordUnlinked = onDiscordUnlinked;
    }

    onUserUpdated = async (user) => {
        if (user) {
            let response = await this.get("profiles/@self");
            let userProfile = response.data;
            user = {
                steamId: userProfile.steamId,
                userName: userProfile.personaName,
                avatar: userProfile.avatarFull,
                discordId: (userProfile.discord && userProfile.discord.id) || undefined,
                discordName: (userProfile.discord && userProfile.discord.userName) || undefined,
                discordDiscriminator: (userProfile.discord && userProfile.discord.discriminator) || undefined
            }
        }
        this._onUserUpdated(user);
    }

    _initialize = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const authcode = urlParams.get('code');

        if (authcode) {
            urlParams.delete('code');
            window.history.pushState(null, document.title, window.location.origin);

            const token = await this.obtainInitialTokens(authcode);
            if (!token) return;

            const decodedAccessToken = jwt_decode(token.accessToken);
            await this.storeAuthentication(token.accessToken, decodedAccessToken.exp * 1000, token.refreshToken, decodedAccessToken.nameid, decodedAccessToken.name)
            return;
        }

        let accessToken = window.localStorage.getItem(AuthService.accessTokenLocalStorageKey);
        let refreshToken = window.localStorage.getItem(AuthService.refreshTokenLocalStorageKey);

        // No refresh token found? bail out, we're done here..
        if (!refreshToken) return;

        // Was an access token present?
        // If so lets try and decode it to obtain it's expiration
        if (!!accessToken) {
            try {
                let decodedAccessToken = jwt_decode(accessToken);
                const tokenExpiration = decodedAccessToken.exp * 1000;
                // We managed to decode the access token, if it's still valid lets set it as the authentication and continue on.
                if (tokenExpiration > Date.now()) {
                    await this.storeAuthentication(accessToken, tokenExpiration, refreshToken, decodedAccessToken.nameid, decodedAccessToken.name);
                    return;
                }
            }
            catch {
                // We failed at some point in retrieving a new token, do nothing
            }
        }

        try {
            // We failed to find a locally cached access token that was valid, time to try and use our refresh token to obtain a new access token.
            let accessTokenResponse = await this.refreshAccessToken(refreshToken);
            // We got a new access token, lets decode it.
            let decodedAccessToken = jwt_decode(accessTokenResponse.accessToken);
            const tokenExpiration = decodedAccessToken.exp * 1000;
            // We managed to decode the access token, if it's still valid lets set it as the authentication and continue on.
            if (tokenExpiration > Date.now()) {
                await this.storeAuthentication(accessTokenResponse.accessToken, tokenExpiration, accessTokenResponse.refreshToken, decodedAccessToken.nameid, decodedAccessToken.name);
                return;
            }
        } catch (e) {
            // We failed at some point in retrieving a new token, do nothing
            console.error('failure obtaining new access token', e);
        }
    }

    storeAuthentication = async (accessToken, tokenExpiration, refreshToken, id, name) => {
        window.localStorage.setItem(AuthService.accessTokenLocalStorageKey, accessToken);
        window.localStorage.setItem(AuthService.refreshTokenLocalStorageKey, refreshToken);

        if (this.accessTokenExpiringTimeout) clearTimeout(this.accessTokenExpiringTimeout);
        this.accessTokenExpiringTimeout = setTimeout(this.onAccessTokenExpiring, tokenExpiration - Date.now() - 60000);
        if (this.accessTokenExpiredTimeout) clearTimeout(this.accessTokenExpiredTimeout);
        this.accessTokenExpiredTimeout = setTimeout(this.onAccessTokenExpired, tokenExpiration - Date.now());

        this.accessToken = accessToken;
        this.expiration = tokenExpiration;
        this.refreshToken = refreshToken;

        await this.onUserUpdated({
            id: id,
            name: name
        });
    }

    onAccessTokenExpiring = async () => {
        try {
            let accessTokenResponse = await this.refreshAccessToken(this.refreshToken);
            let decodedAccessToken = jwt_decode(accessTokenResponse.accessToken);
            const tokenExpiration = decodedAccessToken.exp * 1000;
            if (tokenExpiration > Date.now()) {
                await this.storeAuthentication(accessTokenResponse.accessToken, tokenExpiration, accessTokenResponse.refreshToken, decodedAccessToken.nameid, decodedAccessToken.name);
            }
        } catch (e) {
            // We failed at some point in retrieving a new token, do nothing
            console.error('failure obtaining new access token', e);
        }
    }

    onAccessTokenExpired = () => {
        this.accessToken = undefined;
        this.expiration = undefined;
        this.refreshToken = undefined;
        this.onUserUpdated(undefined);
    }

    obtainInitialTokens = async (code) => {
        try {
            let response = await this.post('token?code=' + code);
            return response.data;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    refreshAccessToken = async (refreshToken) => {
        try {
            let response = await this.post('token/refresh?refresh-token=' + refreshToken);
            return response.data;
        } catch {
            return undefined;
        }
    }

    revokeRefreshToken = async (refreshToken) => {
        try {
            await this.post('token/revoke?refresh-token=' + refreshToken);
        } catch {

        }
    }

    login = () => {
        window.location.href =
            this.baseUrl +
            "/login" +
            "?callbackUrl=" +
            window.location.origin +
            "&errorUrl=" +
            window.location.origin;
    }

    logout = async () => {
        if (this.refreshToken) {
            await this.revokeRefreshToken(this.refreshToken);
        }

        await this.onUserUpdated(undefined);

        if (this.accessTokenExpiringTimeout) clearTimeout(this.accessTokenExpiringTimeout);
        if (this.accessTokenExpiredTimeout) clearTimeout(this.accessTokenExpiredTimeout);
        this.accessToken = undefined;
        this.refreshToken = undefined;
        this.expiration = undefined;

        window.localStorage.removeItem(AuthService.accessTokenLocalStorageKey);
        window.localStorage.removeItem(AuthService.refreshTokenLocalStorageKey);
    }

    linkDiscord = (returnUrl) => {
        returnUrl = returnUrl ?? window.location.origin;
        const processUrl = this.baseUrl + "/discord/link/process?callbackUrl=" + encodeURIComponent(returnUrl) + "&errorUrl=" + encodeURIComponent(returnUrl)
        return this.get("discord/link")
            .then((res) => { window.location = processUrl + "&code=" + encodeURIComponent(res.data.code); });
    }

    unlinkDiscord = () => this.get('discord/unlink')
        .then(() => this.onDiscordUnlinked(undefined));
}