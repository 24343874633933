import { Component, useContext } from 'react';
import { ApiContext } from '../api/context';
import CenterWrapper from './CenterWrapper';
import logo from '../assets/images/RaidingSimulatorLogo_Transparent.png';

class Login extends Component {

    redirectToStore = () => {
        window.location.href = 'https://store.raidsimulator.com/';
    }

    render() {
        return (
            <CenterWrapper>
                <div className="wrap-vital p-b-160 p-t-50">
                    <img alt="Logo" width="270px" className="p-b-43" src={logo} />

                    <div className="container-vital-form-btn">
                        <button onClick={this.redirectToStore} className="steambutton">
                            <span>Visit Our Store</span>
                            <div className="icon">
                                <i className="fa fa-shopping-cart"></i>
                            </div>
                        </button>
                    </div>
                </div>
            </CenterWrapper>
        );
    }
}

export default function Wrapper(props) {
    const apiContext = useContext(ApiContext);
    return <Login {...props} api={apiContext} />
}
