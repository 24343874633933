import { useContext } from 'react';
import 'json.date-extensions';
import { ApiContext, ApiProvider } from './api/context';
import Login from './components/Login';
import Profile from './components/Profile';
import CenterWrapper from './components/CenterWrapper';

function App() {
  // Added Global DateParsing to all JSON deserialization
  JSON.useDateParser();

  return (
    <ApiProvider>
      <Bootstrap />
    </ApiProvider>
  );
}

function Bootstrap() {
  const apiContext = useContext(ApiContext)

  if (!apiContext.ready)
    return (
      <CenterWrapper>
        <h1 className="vital-title">Loading</h1>
      </CenterWrapper>
    )

  if (!apiContext.loggedIn)
    return <Login />;

  return <Profile />;
}

export default App;
