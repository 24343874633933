const production = {
    api_base_url: 'https://api.vitalgamenetwork.com',
    auth_base_url: 'https://auth.vitalgamenetwork.com',
}

const local = {
    api_base_url: 'http://localhost:8080',
    auth_base_url: 'http://localhost:8080',
}

let config;

switch (process.env.REACT_APP_STAGE) {
    case "local":
        config = local;
        break;

    default:
        config = production;
        break;
}

export default config;