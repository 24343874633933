import React, { createContext } from "react";
import { AuthService } from './services/AuthService';
import { PaymentsService } from './services/PaymentsService';
import config from "../config";

const apiBaseUrl = config.api_base_url;
const authBaseUrl = config.auth_base_url;

export const ApiContext = createContext({
    ready: false,
    loggedIn: false,
    user: undefined,
    authService: undefined,
    paymentsService: undefined
});

export class ApiProvider extends React.Component {
    constructor(props) {
        super(props);
        this.authService = new AuthService(this.onUserUpdated, this.onDiscordUnlinked, authBaseUrl);
        this.paymentsService = new PaymentsService(apiBaseUrl, () => this.authService.accessToken);

        this.state = {
            ready: false,
        };
    }

    componentDidMount() {
        this.authService._initialize().finally(() => this.setState({ ready: true }))
    }

    onUserUpdated = (user) => {
        this.setState({ user });
    }

    onDiscordUnlinked = () => {
        if (!this.state.user) return;
        this.setState({
            user: {
                ...this.state.user,
                discordId: undefined,
                discordName: undefined,
                discordDiscriminator: undefined
            }
        })
    };

    render() {
        return (
            <ApiContext.Provider value={{
                ready: this.state.ready,
                loggedIn: !!this.state.user,
                user: this.state.user,
                authService: this.authService,
                paymentsService: this.paymentsService
            }}>
                {this.props.children}
            </ApiContext.Provider>
        )
    }
}