import { Component } from "react";

export default class DiscordInvite extends Component {
    _inviteUrl = "https://discordapp.com/api/v6/invite/"
    _queryParams = "?with_counts=true";

    state = {
        playersOnline: undefined,
        playersTotal: undefined,
        serverId: undefined,
        serverName: undefined,
        serverIcon: undefined
    }

    componentDidMount() {
        if (!this.props.inviteCode) return;

        const reqInit = {
            method: 'GET',
            credentials: 'include'
        };

        let that = this;
        fetch(that._inviteUrl + that.props.inviteCode + that._queryParams, reqInit)
            .then(res => res.json())
            .then(res => {
                this.setState({
                    playersOnline: res.approximate_presence_count,
                    playersTotal: res.approximate_member_count,
                    serverId: res.guild.id,
                    serverName: res.guild.name,
                    serverIcon: res.guild.icon,
                });
            })
            .catch(err => {
                console.error("failure loading discord information " + this.props.inviteCode);
            })
    }

    render() {
        if (!this.state.serverName) return null;

        return (
            <div className="discord-invite">
                <img alt="server-logo" className="discord-invite-icon" src={'https://cdn.discordapp.com/icons/' + this.state.serverId + '/' + this.state.serverIcon + '.jpg'} />
                <div className="discord-invite-details">
                    <span className="discord-invite-servername">{this.state.serverName}</span>
                    <div className="discord-invite-members">
                        <div className="discord-invite-member-group">
                            <span className="discord-status-circle status-online" />
                            <span className="discord-invite-members">{this.state.playersOnline} Online</span>
                        </div>
                        <div className="discord-invite-member-group">
                            <span className="discord-status-circle status-offline" />
                            <span className="discord-invite-members">{this.state.playersTotal} Members</span>
                        </div>
                    </div>
                </div>
                <button onClick={(e) => window.open("https://discord.com/invite/" + this.props.inviteCode, "_blank")} className="discord-invite-join">Join</button>
            </div>
        );
    }
}